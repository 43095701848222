<template>
  <div class="vg_wrapper">
    <el-card>
      <el-row>
        <el-col> </el-col>
        <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"
          ><i class="el-icon-refresh-right"></i> 刷新</el-button
        >
      </el-row>
      <!-- <el-row v-show="smplCount>0">
        <el-col :md="24">
          <el-table ref="multiTable" :data="tableData" border v-loading="loading" @row-dblclick="dbClickJp"
            class="mtrTab">
            <el-table-column label="委托单号" prop="requ_no" width="300" />
            <el-table-column label="样品编号" align="center" prop="smpl_no_list" :formatter="smplNoListFma" width="450" />
            <el-table-column label="发送时间" align="center" prop="scan_time">
              <template slot-scope="scope">
                {{ formatDate(scope.row.scan_time) }}
              </template>
            </el-table-column>
            <el-table-column label="发送状态" align="center" prop="scan">
              <template slot-scope="scope">
                <span v-if="scope.row.scan == 1">发送成功</span>
                <span v-else style="color:red">发送失败</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="80px">
              <template slot-scope="scope">
                <span v-if="scope.row.scan == 1"></span>
                <div v-else>
                  <el-button type="text" size="mini" @click="toCompleted(scope.$index, scope.row)"
                    v-show="scope.row.scan">
                    重发</el-button>
                  <span v-show="!scope.row.scan" class="vd_completed">已重发</span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row> -->

      <el-row>
        <el-col :md="24">
          <el-table ref="multiTable" :data="tableData2" border v-loading="loading" class="mtrTab">
            <el-table-column label="样品单号" prop="smpl_no_list" :formatter="smplNoListFma" />
            <!-- <el-table-column label="邮寄公司" align="center" prop="smpl_ship_comp" width="150px" />
            <el-table-column label="邮寄单号" align="center" prop="smpl_ship_odd" width="300px" /> -->
            <el-table-column label="发送时间" align="center" width="200">
              <template slot-scope="scope">
                {{ formatDate(scope.row.smpl_ship_time) }}
              </template>
            </el-table-column>
            <el-table-column label="发送状态" align="center" prop="scan" width="150px">
              <template slot-scope="scope">
                <span v-if="scope.row.scan == 1">发送成功</span>
                <span v-else style="color: red">发送失败</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="120px">
              <template slot-scope="scope">
                <span v-if="scope.row.scan == 1"></span>
                <div v-else>
                  <el-button type="text" size="mini" @click="toCompleted2(scope.$index, scope.row)" v-show="scope.row.scan">
                    重发</el-button
                  >
                  <span v-show="!scope.row.scan" class="vd_completed">已重发</span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { requAPI } from '@api/modules/requ';
import { smplAPI } from '@api/modules/smpl';
export default {
  name: 'MailPush9003',
  data() {
    return {
      tableData: [],
      tableData2: [],
      loading: true,
      isEditObj: [],
      mailCount: 0,
      smplCount: 0
    };
  },
  created() {
    this.initData();
  },
  mounted() {
    for (let i in this.tableData) {
      let scan = 'edit' + i;
      this.isEditObj.push({
        scan: true
      });
    }
  },
  // filters:{
  //   // 物料类型
  //   formatLeavType(row){
  //     if(row === 0){
  //       return '原面料'
  //     } else if(row === 1){
  //       return '辅料'
  //     } else if(row === 2){
  //       return '包材'
  //     }
  //   },
  //   // 单价
  //   formatMtrlPrice(row){
  //     return helper.haveFour(row)
  //   },
  //   formatMtrlWidth(row){
  //     return helper.haveFour(row)+'米'
  //   },
  //   formatMtrlThick(row){
  //     return helper.reservedDigits(row)+'厘米'
  //   }
  // },
  methods: {
    initData() {
      this.getPushListForRequ();
      this.getPushPostForMail();
    },

    //获取样单发送信息
    getPushListForRequ() {
      get(requAPI.getPushRequMails9003)
        .then(res => {
          if (res.data.code === 0) {
            this.loading = false;
            this.tableData = res.data.data;
            this.smplCount = Object.keys(this.tableData).length;
            console.log('样单信息,', this.smplCount);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取邮寄发送信息
    getPushPostForMail() {
      get(smplAPI.getPushPostMails9003)
        .then(res => {
          if (res.data.code === 0) {
            this.loading = false;
            this.tableData2 = res.data.data;
            this.mailCount = Object.keys(this.tableData2).length;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDate(row);
    },
    //样单信息操作
    toCompleted(index, row) {
      get(requAPI.updateScanById, { requ_id: row.requ_id })
        .then(res => {
          if (res.data.code === 0) {
            this.$set(row, 'scan', false);
            this.$message({ message: '重发成功，请稍后查看状态', type: 'success' });
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 邮寄信息操作
    toCompleted2(index, row) {
      get(smplAPI.updateScanById, { requ_id: row.requ_id })
        .then(res => {
          if (res.data.code === 0) {
            this.$set(row, 'scan', false);
            this.$message({ message: '重发成功，请稍后查看状态', type: 'success' });
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 双击
    dbClickJp(row) {
      this.jump('/requ_edit', { perm_id: this.$route.query.perm_id, form_id: row.requ_id, requ_id: row.requ_id });
    },
    // 刷新按钮
    buttonRefresh() {
      this.getPushListForRequ();
      this.getPushPostForMail();
    },
    // 格式化样品编号
    smplNoListFma(row) {
      return row.smpl_no_list.join(',');
    }
  }
};
</script>

<style scoped lang="scss">
.vd_completed {
  color: $color-secondary-text;
}

.mtrTab {
  margin-top: 10px;
  cursor: pointer;
}
</style>
